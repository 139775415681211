import {
  type Appearance,
  type ExpressPaymentType,
  type StripeCardElementChangeEvent,
  type StripeExpressCheckoutElementOptions,
  type StripePaymentElementOptions,
} from '@stripe/stripe-js';

import { stripeColors } from '@/components/paymentProviders/Stripe';
import { ColorTheme } from '@/components/common/ThemeContext/typedefs';
import { MANROPE_GOOGLE_FONT_URL } from '@/constants/fonts';
import { PaymentMethods } from '@/controllers/analytics/generated';

export const getStripeElementsAppearenceOptions = (
  isDarkMode: boolean,
): Appearance => {
  const mode = isDarkMode
    ? ColorTheme.Dark
    : ColorTheme.Light;

  return {
    variables: {
      fontFamily: 'Manrope, sans-serif',
      colorBackground: stripeColors[`background-${mode}`],
      borderRadius: '4px',
      spacingUnit: '4px',
      colorDanger: stripeColors[`danger-${mode}`],
      iconColor: stripeColors[`icon-${mode}`],
      colorTextSecondary: stripeColors[`text-secondary-${mode}`],
      colorText: stripeColors[`text-primary-${mode}`],
    },
    rules: {
      '.Input': {
        border: '1px solid transparent',
        outline: 'none',
        boxShadow: 'none',
        padding: '14px 12px',
        fontSize: '16px',
        color: stripeColors[`input-text-${mode}`],
      },
      '.Input::placeholder': {
        color: stripeColors[`input-placeholder-${mode}`],
      },
      '.Input:focus': {
        borderColor: stripeColors[`input-focus-border-${mode}`],
        outline: 'none',
        boxShadow: 'none',
      },
      '.Input--invalid': {
        borderColor: stripeColors[`input-invalid-border-${mode}`],
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: stripeColors[`input-invalid-background-${mode}`],
        color: stripeColors[`input-text-${mode}`],
      },
      '.Input:focus.Input--invalid': {
        borderColor: stripeColors[`input-invalid-focus-border-${mode}`],
      },
      '.Label': {
        fontSize: '14px',
        color: stripeColors[`label-${mode}`],
        fontWeight: '800',
        lineHeight: '19px',
      },
      '.Error': {
        fontSize: '12px',
        color: stripeColors[`error-${mode}`],
      },
    },
  };
};

export const STRIPE_ELEMENTS_FONTS = [
  {
    cssSrc: MANROPE_GOOGLE_FONT_URL,
  },
];

export const STRIPE_EXPRESS_CHECKOUT_OPTIONS
  : StripeExpressCheckoutElementOptions = {
    buttonType: {
      googlePay: 'plain',
      applePay: 'plain',
    },
    paymentMethods: {
      googlePay: 'auto',
      paypal: 'auto',
      applePay: 'auto',
    },
  };

export const STRIPE_FREE_TRIAL_EXPRESS_CHECKOUT_OPTIONS
  : StripeExpressCheckoutElementOptions = {
    buttonHeight: 40,
    buttonType: {
      googlePay: 'subscribe',
      applePay: 'subscribe',
    },
    paymentMethods: {
      googlePay: 'auto',
      applePay: 'auto',
      paypal: 'auto',
    },
  };

export const PAYMENT_ELEMENT_OPTIONS: StripePaymentElementOptions = {
  paymentMethodOrder: ['card'],
  fields: {
    billingDetails: {
      email: 'never',
    },
  },
  wallets: {
    googlePay: 'never',
    applePay: 'never',
  },
  terms: {
    card: 'never',
  },
};

export const PINGBACK_WAITING_TIME = 2000;

export const EMPTY_CHECKOUT_FIELDS_ERROR: Exclude<StripeCardElementChangeEvent['error'], undefined> = {
  type: 'validation_error',
  code: 'empty_fields',
  message: '',
};

export const STRIPE_EMAIL_INVALID_CODE = 'email_invalid';
export const STRIPE_PAYMENT_METHODS_MAP: Record<
  ExpressPaymentType,
  PaymentMethods
> = {
  apple_pay: PaymentMethods.ApplePay,
  google_pay: PaymentMethods.GooglePay,
  paypal: PaymentMethods.Paypal,
  link: PaymentMethods.Card,
  amazon_pay: PaymentMethods.AmazonPay,
};
