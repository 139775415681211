import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  Chubbs500Light,
} from '@/components/ui/Chubbs/Chubbs500/Chubbs500.light';
import {
  Chubbs500Dark,
} from '@/components/ui/Chubbs/Chubbs500/Chubbs500.dark';

export const Chubbs500: FC<ChubbsProps> = memo((props) => (
  <Chubbs
    LightChubbs={Chubbs500Light}
    DarkChubbs={Chubbs500Dark}
    {...props}
  />
));
