import {
  type FC,
  useCallback,
} from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { type WithStatusCode } from '@/typedefs/pageProps';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { ROUTES } from '@/controllers/router/router.contants';
import { ActiveLink } from '@/components/common/ActiveLink';
import {
  ChubbsUndefined,
} from '@/components/ui/Chubbs/ChubbsUndefined/ChubbsUndefined';
import {
  Chubbs500,
} from '@/components/ui/Chubbs/Chubbs500/Chubbs500';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import getClientLink from '@/lib/helpers/getClientLink';
import styles from './Error.module.scss';

export interface Props extends WithStatusCode {
  className?: string;
  description?: string;
  redirectUrl?: string;
  shouldRedirect?: boolean;
}

export const Error: FC<Props> = (props) => {
  const {
    code,
    className,
    description,
    redirectUrl = ROUTES.home,
    shouldRedirect = true,
  } = props;
  const { t, i18n } = useTranslation([I18N_CODES.error, I18N_CODES.common]);

  const errorDescription = description || (
    (code && code >= 500)
      ? 'error_something_wrong'
      : 'error_description'
  );

  const ErrorChubb = (code && code >= 500)
    ? Chubbs500
    : ChubbsUndefined;

  const { isSubscriptionProductDomain, subDomain } = useSubDomainContext();

  const homeHref = getClientLink(ROUTES.home, {
    domain: subDomain,
    language: i18n.language,
  });

  const redirectToHome = useCallback(() => {
    window.location.href = homeHref;
  }, [homeHref]);

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className="pl-16 pr-16">
        {code && (
          <h1 className={cn(typography.landingH6, styles.errorCode, 'text-center mb-32')} data-qa='error-message'>
            {t(`${I18N_CODES.error}:error_message`, {
              code,
            })}
          </h1>
        )}
        <p className={cn(typography.landingH2, styles.headerText, 'text-center mb-40')}>
          {t(`${I18N_CODES.error}:${errorDescription}`)}
        </p>

        {shouldRedirect && (
          <ActiveLink href={ROUTES.home}>
            <Button
              href={!isSubscriptionProductDomain
                ? redirectUrl
                : undefined}
              onClick={isSubscriptionProductDomain
                ? redirectToHome
                : undefined}
              text={t(`${I18N_CODES.common}:go_home`)}
              title={t(`${I18N_CODES.common}:go_home`)}
              size={Button.size.Large}
              mode={Button.mode.Primary}
              className={styles.button}
            />
          </ActiveLink>
        )}
      </div>

      <div className={styles.chubbsContainer}>
        <ErrorChubb isAutoSize />
      </div>
    </div>
  );
};
