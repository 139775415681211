import { type FC } from 'react';
import { type ProcessSubscriptionPaymentPricingOption, type ProcessSubscriptionPaymentSubscriptionPlan } from '@/components/platform/SubscriptionProduct/typedefs';
import { useGetPrivaciesDocs } from '@/controllers/landing/landing.helpers';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import {
  Currencies,
} from '@/controllers/graphql/generated';
import { getRebillTranslateCode } from '@/components/paymentProviders/Stripe/helpers';
import styles from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/TermsAndConditions/StripeCheckoutTermsAndConditions.module.scss';

interface Props {
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
}

export const StripeCheckoutTermsAndConditions: FC<Props> = ({
  subscriptionPlan,
  pricingOption,
}) => {
  const {
    period,
    periodDuration,
  } = subscriptionPlan;

  const { t } = useTranslation([I18N_CODES.payment]);

  const currency = pricingOption?.currency ?? subscriptionPlan.currency;

  const formatter = useNumberFormatter({
    style: 'currency',
    currency: currency?.code ?? Currencies.Usd,
  });

  const rebillCode = getRebillTranslateCode(
    pricingOption?.paymentType ?? subscriptionPlan.paymentType,
  );

  const { termsOfUse } = useGetPrivaciesDocs();

  const planRenewalPrice = (
    pricingOption?.renewalPrice ?? subscriptionPlan.salePrice
  );

  const planIntroPrice = (
    pricingOption?.salePrice ?? subscriptionPlan.trialPrice
  );

  return (
    <p
      className={styles.terms}
      data-qa='stripe-terms-and-conditions'
      dangerouslySetInnerHTML={{
        __html: t(`${I18N_CODES.payment}:${rebillCode}`, {
          initialPrice: planIntroPrice
            ? formatter.format(planIntroPrice)
            : '',
          rebillPrice: formatter.format(planRenewalPrice),
          billingPeriod: t(`${I18N_CODES.payment}:stripe.subscription_period.${period}`, {
            count: periodDuration,
          }),
          termsOfUse,
        }),
      }}
    />
  );
};
