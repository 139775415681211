import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const Chubbs500Light: FC<ChubbsProps> = memo((props) => (
  <ChubbsWrapper initialHeight={202} initialWidth={244} {...props}>
    <g transform="translate(-20, 11)" clipPath="url(#clip0_11219_65822)">
      <path fillRule="evenodd" clipRule="evenodd" d="M163.446 37.2509C165.274 54.8368 165.253 54.5471 167.081 72.1537C171.235 115.601 204.032 126.318 207.418 190.082C207.895 198.875 193.501 182.427 193.356 194.22C183.698 197.634 156.156 190.662 146.477 194.075C136.341 192.669 113.826 214.93 103.69 213.523C99.847 202.186 95.7345 195.524 95.0075 186.441C90.9572 135.794 106.265 115.891 108.051 94.8705C108.486 90.3499 108.486 85.7982 108.051 81.2776L104.105 43.2922C99.9717 4.29294 159.5 -0.403523 163.446 37.2509Z" fill={colorTokens['chubbs-body']} />
      <path d="M163.446 37.2509C165.274 54.8368 165.253 54.5471 167.081 72.1537C171.235 115.601 204.032 126.318 207.418 190.082C207.895 198.875 193.501 182.427 193.356 194.22C183.698 197.634 156.156 190.662 146.477 194.075C136.341 192.669 113.826 214.93 103.69 213.523C99.847 202.186 95.7345 195.524 95.0075 186.441C90.9572 135.794 106.265 115.891 108.051 94.8705C108.486 90.3499 108.486 85.7982 108.051 81.2776L104.105 43.2922C99.9717 4.29294 159.5 -0.403526 163.446 37.2509Z" stroke="#0F0F10" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M207.273 184.413C207.273 184.848 207.273 185.261 207.273 185.696C207.647 205.702 202.932 216.73 202.973 234.998C203.742 243.564 212.507 288.314 211.032 304.204C208.789 328.183 202.371 379.285 202.288 380.506C203.929 381.023 108.924 381.83 109.36 381.002C109.36 381.002 118.603 309.686 118.229 304.721C117.129 289.908 111.998 267.956 109.672 251.384C108.571 243.357 109.817 242.943 104.002 236.447C97.6043 229.267 89.2546 213.978 95.6934 190.185C96.1089 189.441 195.683 185.054 207.273 184.413Z" fill={colorTokens['chubbs-body']} />
      <path d="M207.273 184.413C207.273 184.848 207.273 185.261 207.273 185.696C207.647 205.702 202.932 216.73 202.973 234.998C203.742 243.564 212.507 288.314 211.032 304.204C208.789 328.183 202.371 379.285 202.288 380.506C203.929 381.023 108.924 381.83 109.36 381.002C109.36 381.002 118.603 309.686 118.229 304.721C117.129 289.908 111.998 267.956 109.672 251.384C108.571 243.357 109.817 242.943 104.002 236.447C97.6043 229.267 89.2546 213.978 95.6934 190.185C96.1089 189.441 195.683 185.054 207.273 184.413Z" stroke="#0F0F10" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M163.215 57.9808C162.533 58.051 161.845 57.9179 161.238 57.5984C160.631 57.2788 160.133 56.7872 159.807 56.1857C159.481 55.5842 159.341 54.8999 159.406 54.2193C159.47 53.5388 159.736 52.8925 160.169 52.3625C160.603 51.8324 161.184 51.4422 161.84 51.2414C162.496 51.0405 163.197 51.038 163.855 51.2342C164.512 51.4303 165.096 51.8163 165.533 52.3433C165.97 52.8703 166.241 53.5146 166.31 54.1947C166.359 54.6461 166.318 55.1027 166.188 55.5381C166.059 55.9734 165.844 56.3788 165.556 56.7309C165.268 57.083 164.913 57.3748 164.511 57.5893C164.11 57.8039 163.669 57.9369 163.215 57.9808Z" fill="white" />
      <path d="M163.215 57.9808C162.533 58.051 161.845 57.9179 161.238 57.5984C160.631 57.2788 160.133 56.7872 159.807 56.1857C159.481 55.5842 159.341 54.8999 159.406 54.2193C159.47 53.5388 159.736 52.8925 160.169 52.3625C160.603 51.8324 161.184 51.4422 161.84 51.2414C162.496 51.0405 163.197 51.038 163.855 51.2342C164.512 51.4303 165.096 51.8163 165.533 52.3433C165.97 52.8703 166.241 53.5146 166.31 54.1947C166.359 54.6461 166.318 55.1027 166.188 55.5381C166.059 55.9734 165.844 56.3788 165.556 56.7309C165.268 57.083 164.913 57.3748 164.511 57.5893C164.11 57.8039 163.669 57.9369 163.215 57.9808V57.9808Z" stroke="#0F0F10" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M159.336 42.4849C160.266 41.4743 161.471 40.7553 162.805 40.416" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M109.188 88.2079L168.072 82.2287C169.174 82.1162 170.275 82.4443 171.134 83.1408C171.993 83.8372 172.539 84.845 172.652 85.9424C172.765 87.0399 172.436 88.137 171.736 88.9926C171.037 89.8481 170.025 90.3919 168.924 90.5044L110.039 96.6077C109.494 96.6634 108.942 96.6116 108.417 96.4551C107.892 96.2986 107.402 96.0406 106.977 95.6957C106.552 95.3509 106.199 94.9259 105.938 94.4452C105.678 93.9645 105.515 93.4374 105.459 92.894C105.403 92.3506 105.455 91.8016 105.613 91.2783C105.77 90.7549 106.029 90.2675 106.375 89.8439C106.721 89.4203 107.148 89.0687 107.63 88.8093C108.113 88.5499 108.642 88.3877 109.188 88.332V88.2079Z" fill={colorTokens['chubbs-body']} />
      <path d="M109.188 88.2079L168.072 82.2287C169.174 82.1162 170.275 82.4443 171.134 83.1408C171.993 83.8372 172.539 84.845 172.652 85.9424C172.765 87.0399 172.436 88.137 171.736 88.9926C171.037 89.8481 170.025 90.3919 168.924 90.5044L110.039 96.6077C109.494 96.6634 108.942 96.6116 108.417 96.4551C107.892 96.2986 107.402 96.0406 106.977 95.6957C106.552 95.3509 106.199 94.9259 105.938 94.4452C105.678 93.9645 105.515 93.4374 105.459 92.894C105.403 92.3506 105.455 91.8016 105.613 91.2783C105.77 90.7549 106.029 90.2675 106.375 89.8439C106.721 89.4203 107.148 89.0687 107.63 88.8093C108.113 88.5499 108.642 88.3877 109.188 88.332V88.2079Z" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
      <path d="M103.664 39.8576L194.244 30.6509" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M170.717 90.0298L172.189 93.8477C160.519 93.1604 147.631 93.1482 135.961 93.8356L170.717 90.0298Z" fill="#0F0F10" />
      <path d="M170.717 90.3232L171.894 93.8475C160.225 93.1601 147.631 93.148 135.961 93.8353L170.717 90.3232Z" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M162.771 33.9355L163.423 37.3125C151.752 36.646 140.053 36.646 128.383 37.3125L162.771 33.9355Z" fill="#0F0F10" />
      <path d="M162.771 33.9355L163.423 37.3125C151.752 36.646 140.053 36.646 128.383 37.3125L162.771 33.9355Z" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M163.754 52.3789C163.806 52.3731 163.858 52.3777 163.908 52.3923C163.958 52.4069 164.005 52.4313 164.046 52.464C164.086 52.4967 164.12 52.5371 164.145 52.5829C164.17 52.6286 164.185 52.6788 164.19 52.7306C164.196 52.8339 164.164 52.9358 164.098 53.0162C164.033 53.0966 163.94 53.1497 163.837 53.165C163.785 53.1708 163.733 53.1662 163.683 53.1516C163.632 53.137 163.586 53.1126 163.545 53.0799C163.505 53.0472 163.471 53.0068 163.446 52.961C163.421 52.9153 163.406 52.8651 163.401 52.8133C163.395 52.7617 163.4 52.7094 163.414 52.6595C163.429 52.6096 163.453 52.5631 163.486 52.5227C163.519 52.4823 163.56 52.4488 163.606 52.4241C163.652 52.3994 163.702 52.384 163.754 52.3789Z" fill="#0F0F10" />
      <path d="M163.754 52.3789C163.806 52.3731 163.858 52.3777 163.908 52.3923C163.958 52.4069 164.005 52.4313 164.046 52.464C164.086 52.4967 164.12 52.5371 164.145 52.5829C164.17 52.6286 164.185 52.6788 164.19 52.7306C164.196 52.8339 164.164 52.9358 164.098 53.0162C164.033 53.0966 163.94 53.1497 163.837 53.165C163.785 53.1708 163.733 53.1662 163.683 53.1516C163.632 53.137 163.586 53.1126 163.545 53.0799C163.505 53.0472 163.471 53.0068 163.446 52.961C163.421 52.9153 163.406 52.8651 163.401 52.8133C163.395 52.7617 163.4 52.7094 163.414 52.6595C163.429 52.6096 163.453 52.5631 163.486 52.5227C163.519 52.4823 163.56 52.4488 163.606 52.4241C163.652 52.3994 163.702 52.384 163.754 52.3789Z" stroke="#0F0F10" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M258.383 219.854C256.784 226.205 250.262 223.329 247.042 222.895C245.297 223.559 243.414 223.786 241.559 223.557C234.539 221.798 239.731 220.702 228.868 210.75C210.163 195.463 186.143 170.055 175.924 150.752C173.367 145.328 170.388 140.112 167.014 135.152C161.011 126.876 146.804 105.132 174.076 116.407C182.945 120.069 179.688 112.322 185.732 120.867C201.627 142.893 220.435 169.455 240.915 187.351C248.995 194.406 250.712 194.986 262.302 199.93C265.916 201.441 268.554 211.123 265.417 212.344C264.046 212.882 254.72 203.654 252.955 204.068C249.735 204.813 258.466 209.488 256.265 208.64C254.665 208.019 254.998 217.847 258.342 219.833" fill={colorTokens['chubbs-body']} />
      <path d="M252.59 215.137C255.644 217.537 260.525 217.826 257.492 221.343C253.006 226.392 245.03 221.033 241.956 218.116C240.045 216.316 236.909 218.364 234.021 215.675C223.636 205.061 186.665 178.02 166.164 134.014" stroke="#0F0F10" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M254.317 216.482C251.076 214.413 243.931 207.523 247.005 206.571C248.252 206.096 254.815 208.123 256.414 208.744C258.948 209.696 263.809 213.482 265.969 212.882C266.841 212.633 267.444 206.323 265.2 202.992C261.773 197.944 251.969 195.999 250.931 195.627C247.4 194.385 244.4 190 240.92 187.351C220.44 169.455 200.812 141.835 186.75 122.139" stroke="#0F0F10" strokeWidth="0.57" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M165.848 134.131C166.019 134.036 166.235 134.112 166.317 134.289C167.064 135.89 168.861 138.492 169.577 140.573C170.88 143.657 172.744 146.552 174.437 149.447C174.516 149.581 174.593 149.708 174.678 149.838C175.787 151.543 184.192 164.378 192.32 174.441C195.507 178.391 201.19 184.325 204.546 187.758C206.263 189.515 207.46 191.831 207.316 194.284C207.272 195.044 207.16 195.593 206.922 195.523C199.299 193.227 170.013 146.593 166.669 137.49C166.342 136.609 166.051 135.587 165.696 134.521C165.647 134.371 165.71 134.207 165.848 134.131Z" fill="#0F0F10" />
      <path d="M159.664 71.6566C159.519 70.6428 161.326 69.2566 163.071 70.767C163.902 71.4704 165.833 71.6359 166.353 70.498C166.498 70.2084 166.913 69.5463 166.872 70.2084" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
      <path d="M152.023 43.499C150.735 45.1955 147.744 51.5058 151.774 50.492C153.435 50.0575 152.542 44.8438 152.023 43.499ZM152.023 43.499C152.19 43.9596 152.037 43.6793 152.023 43.499Z" stroke="#0F0F10" strokeWidth="0.57" strokeMiterlimit="22.93" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_11219_65822">
        <rect width="244" height="202" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));
