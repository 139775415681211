import { IconSuccess } from '@/components/ui/icons/IconSuccess';
import { IconError } from '@/components/ui/icons/IconError';
import { IconInfo } from '@/components/ui/icons/IconInfo';
import { AlertMode } from '@/components/ui/Alert/Alert.typedefs';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const getAlertIcon = (mode: AlertMode): FCIcon<any> => {
  switch (mode) {
    case AlertMode.Success:
      return IconSuccess;
    case AlertMode.Error:
      return IconError;
    default:
      return IconInfo;
  }
};
