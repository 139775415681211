import { type StripeExpressCheckoutElementOptions } from '@stripe/stripe-js';
import { type I18n, type TFunction } from 'next-i18next';
import { I18N_CODES } from '@/lib/constants/general';
import {
  STRIPE_EMAIL_INVALID_CODE,
  STRIPE_EXPRESS_CHECKOUT_OPTIONS,
  STRIPE_FREE_TRIAL_EXPRESS_CHECKOUT_OPTIONS,
} from '@/components/paymentProviders/Stripe/constants';
import {
  type ProcessSubscriptionPaymentPricingOption,
  type ProcessSubscriptionPaymentSubscriptionPlan,
} from '@/components/platform/SubscriptionProduct/typedefs';
import {
  PricingOptionPaymentType,
  SubscriptionPeriod,
  SubscriptionPlanPaymentType,
} from '@/controllers/graphql/generated';
import getClientLink from '@/lib/helpers/getClientLink';
import { ROUTES } from '@/controllers/router/router.contants';
import { type PaymentElementValidationError } from '@/components/paymentProviders/Stripe/typedefs';

export const getExpressCheckoutOptions = (
  isFreeTrialPlan: boolean,
): StripeExpressCheckoutElementOptions => (
  isFreeTrialPlan
    ? STRIPE_FREE_TRIAL_EXPRESS_CHECKOUT_OPTIONS
    : STRIPE_EXPRESS_CHECKOUT_OPTIONS
);

export const getRebillTranslateCode = (
  paymentType: SubscriptionPlanPaymentType | PricingOptionPaymentType,
) => {
  switch (paymentType) {
    case SubscriptionPlanPaymentType.IntroOffer:
    case PricingOptionPaymentType.IntroOffer:
    case SubscriptionPlanPaymentType.PaidTrial:
      return 'stripe.short_rebill_info.intro_offer';
    case SubscriptionPlanPaymentType.FreeTrial:
    case PricingOptionPaymentType.Trial:
      return 'stripe.short_rebill_info.free_trial';
    case SubscriptionPlanPaymentType.UpfrontPayment:
    case PricingOptionPaymentType.Upfront:
    default:
      return 'stripe.short_rebill_info.upfront';
  }
};

export const getDiscountBlockTitle = (options: {
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  t: TFunction;
}) => {
  const {
    subscriptionPlan,
    pricingOption,
    t,
  } = options;

  const paymentType = (
    pricingOption?.paymentType ?? subscriptionPlan.paymentType
  );
  const trialPeriod = (
    pricingOption?.trialPeriod ?? subscriptionPlan.trialPeriod
  );

  const trialPeriodDuration = (
    pricingOption?.trialPeriodDuration ?? subscriptionPlan.trialPeriodDuration
  );

  switch (paymentType) {
    case SubscriptionPlanPaymentType.IntroOffer:
    case PricingOptionPaymentType.IntroOffer:
      return t(`${I18N_CODES.subscriptions}:stripe.intro_offer`);

    case SubscriptionPlanPaymentType.FreeTrial:
    case SubscriptionPlanPaymentType.PaidTrial:
    case PricingOptionPaymentType.Trial:
      return t(`${I18N_CODES.subscriptions}:stripe.trial_period.${trialPeriod?.toLowerCase()}`, {
        count: trialPeriodDuration || 0,
      });

    default:
      return t(`${I18N_CODES.subscriptions}:stripe.discount`);
  }
};

export const getComparisonBasedSavings = (
  t: TFunction,
  i18n: I18n,
  planName: string,
  pricingOptionSlug: string | undefined,
  period: SubscriptionPeriod,
) => {
  if (period === SubscriptionPeriod.Week) {
    return '';
  }

  const planNameCode = planName
    .toLowerCase()
    .replaceAll('.', '')
    .replaceAll(' ', '_');

  if (!pricingOptionSlug) {
    return t(`${I18N_CODES.payment}:stripe.comparison_based_savings.${planNameCode}`);
  }

  const translateCode = `stripe.comparison_based_savings.${planNameCode}_${pricingOptionSlug}`;

  return i18n.exists(`${I18N_CODES.payment}:${translateCode}`)
    ? t(`${I18N_CODES.payment}:${translateCode}`)
    : '';
};

export const getCheckoutRedirectUrl = (paymentIntentId: string) => (
  getClientLink(
    `${ROUTES.auth.signIn}?paymentIntentId=${paymentIntentId}`,
  )
);

export const getStripeValidationErrorCode = (
  stripeError: PaymentElementValidationError | null,
  isEmailInvalid: boolean,
): string | undefined => {
  const stripeErrorCode = stripeError?.error?.code;

  if (stripeErrorCode) {
    return stripeErrorCode;
  }

  return isEmailInvalid
    ? STRIPE_EMAIL_INVALID_CODE
    : undefined;
};
